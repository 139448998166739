<template>
  <div class="container login-register-container">
    <!-- <router-link to="/"> -->
    <img src="@/assets/image/login-register-bg.png" alt="" class="bg" />
    <!-- </router-link> -->
    <div class="box-view">
      <div class="box-title">
        <router-link to="/">
          <img src="@/assets/image/cqc-login-register-title.png" alt="" class="title" />
        </router-link>
      </div>
      <div class="box-link">
        <div class="link-btn-box">
          <span
            :class="[flag ? 'link-btn active' : 'link-btn']"
            @click="toLogin"
            >登录</span
          >
        </div>
        <div class="link-btn-box">
          <span
            :class="[!flag ? 'link-btn active' : 'link-btn']"
            class="link-btn"
            @click="toRegister"
            >注册</span
          >
        </div>
      </div>
      <div class="link-view">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login-register",
  data(){
    return{
      flag: true
    }
  },
  created(){
    let reg = RegExp(/login/);
    // console.log(window.location.hash);
    // console.log(reg.test(window.location.hash));
    this.flag = reg.test(window.location.hash)
  },
  methods:{
    toLogin(){
      this.flag = true
      this.$router.push({
        path: "/user/login",
        query: {
          id: this.$route.query.id || undefined,
          jobNumber: this.$route.query.jobNumber || undefined
        }
      });
    },
    toRegister(){
      this.flag = false
      this.$router.push({
        path: "/user/register",
        query: {
          id: this.$route.query.id || undefined,
          jobNumber: this.$route.query.jobNumber || undefined
        }
      });
    }
  }
};
</script>

<style lang="scss">
.login-register-container {
  position: relative;
  width: 100vw;
  height: 100vh;

  .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .box-view {
    position: absolute;
    width: 460px;
    height: 500px;
    top: 50%;
    left: 50%;
    margin-top: -260px;
    margin-left: -200px;
    background: #ffffff;
    box-shadow: 0 0 20px #999999;
    border-radius: 20px;

    .box-title {
      position: absolute;
      width: 200px;
      height: 64px;
      top: -32px;
      left: 50%;
      margin-left: -100px;
      background: #3e7eff;
      border-radius: 25px;
      box-shadow: 0 0 10px #999;
      line-height: 64px;

      .title {
        width: 80%;
        height: 50%;
        position: absolute;
        top: 25%;
        left: 10%;
      }
    }

    .box-link {
      width: 100%;
      height: 60px;
      line-height: 60px;
      margin-top: 40px;

      .link-btn-box {
        float: left;
        width: 50%;
        text-align: center;

        .link-btn {
          cursor: pointer;
          font-size: 24px;
          color: #666666;
        }

        .active {
          color: #3e7eff;
        }
      }
    }

    .link-view {
      width: 100%;
      height: 300px;
      padding: 20px 60px 20px 60px;
    }
  }

  .el-input,
  .el-input__inner {
    border-radius: 0;
    border: 0;
    padding: 0;
    margin: 0;
  }
  .el-input__inner {
    border-bottom: 1px solid #cccccc;
  }
  .el-button {
    width: 100%;
    background: #3e7eff;
    span {
      font-size: 18px;
    }
  }
}
</style>
